$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.footer-newsletter {
  padding-bottom: 12px;
  max-width: 400px;
}

.footer-newsletter__input {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 40px);

  .input::-ms-clear {
    height: 0;
    width: 0;
  }

  .input--error {
    border-color: #ff7973;
    padding-right: 40px;
  }

  .input--valid {
    border-color: #6db4a9;
    padding-right: 40px;
  }

  .formbox__error {
    color: #ff7973;
  }
}

.footer-newsletter__input-container {
  border-radius: var(--border-radius-300);
  border: solid 1px var(--color-border-primary);
}

.footer-newsletter__input input {
  border: 1px solid var(--color-white);
  border-radius: var(--border-radius-300);
  color: #4a4a4a;
  font-family: 'Source Sans Pro';
  font-size: 1.7rem;
  height: 44px;
  line-height: 22px;
  padding: 0 13px;
  text-align: left;
  transition: border-color .25s ease;
  width: 100%;

  &.input {
    &:hover {
      border-color: var(--color-white);
    }
    &:focus {
      border-color: var(--color-white);
    }
  }

  &.input--error {
    &:hover {
      border-color: #FF7872;
    }
    &:focus {
      border-color: #FF7872;
    }
  }

  &.input--valid {
    &:hover {
      border-color: #6db4a9;
    }
    &:focus {
      border-color: #6db4a9;
    }
  }
}

.footer-newsletter__submit {
  background-color: var(--color-dark-core-500);
  border: none;
  color: var(--color-white);
  cursor: pointer;
  height: 44px;
  transition: color var(--transition), background var(--transition), border var(--transition), opacity var(--transition);
  width: 40px;

  &:hover {
    background-color: var(--color-dark-core-500);
  }

  &:active {
    background: var(--color-dark-core-400);
  }
}

.footer-newsletter__submit svg {
  fill: var(--color-white);
  height: 18px;
  margin-top: 3px;
  width: 18px;
}

.footer-newsletter__success-container {
  color: #6db4a9;
  padding: 20px 0;
}

.footer-newsletter__success-message {
  display: inline;
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
  width: 96%;
}

.footer-newsletter__success-icon {
  margin-right: 9px;
  vertical-align: middle;
  display: inline-block;
}

.footer-newsletter--data-policy-link {
  margin-top: 15px;
}

$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.globe-footer-mail-containers {
  display: inline-block;
  vertical-align: top;
  width: 26%;

  @media (max-width: 1279px) {
    width: 50%;
    margin-top: 30px;
  }

  @media (max-width: $device-width-s-max) {
    width: 100%;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid var(--color-quartz-550);
  }  
}

.globe-footer-mail-registration__div {
  margin-top: 24px;
  display: grid;
  width: 100%;
  grid-template-columns: auto 62px;
}

.globe-footer-mail-registration__input {
  color: var(--color-white);
  background-color: var(--color-black);
  border-bottom: 1px solid var(--color-quartz-50);
  padding-left: 5px;

  font-family: var(--font-family-body);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 21.6px */

  &--error {
    border-color: #ff7973;
    padding-right: 40px;
  }

  &--valid {
    border-color: #6db4a9;
    padding-right: 40px;
  }

  .input--error {
    border-color: #ff7973;
    padding-right: 40px;
  }

  .input--valid {
    border-color: #6db4a9;
    padding-right: 40px;
  }

  &::placeholder {
    color: var(--color-white);
    font-family: var(--font-family-body);
    font-size: 1.6rem;
  }
  
  &::-ms-input-placeholder {
    color: var(--color-white);
    font-family: var(--font-family-body);
    font-size: 1.6rem;
  } 
}

.globe-footer-mail-registration__button {
  display: flex;
  width: 62px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 1px;
  background: var(--color-cta-button-primary, #5e593a);
  color: var(--color-white);
}

.globe-footer-mail-registration__text {
  color: var(--color-white);

  /* Desktop Specific/text/small */
  font-family: var(--font-family-body);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
}

.globe-footer-newsletter__success-message {
  display: inline;
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
  color: var(--color-white);
}
$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.footer-link-list-container {
  display: flex;
  flex-direction: row;
}

.footer-link-list {
  margin: 0 10px 0 0;
  width: 50%;

  &:last-child {
    margin: 0;
  }
}

.footer-link-list__item {
  font-size: 1.6rem;
  line-height: 1.2;
  margin: 0 0 0.875em;
}

.footer-link-list__link {
  border-bottom: currentColor solid 1px;
  transition: border-color var(--transition), color var(--transition);
  color: var(--color-grey-300);

  &:focus-visible {
    box-shadow: none;
    outline: 2px solid var(--color-white);
  }

  .no-touch &:hover {
    border-color: currentColor;
    color: var(--color-white);
  }

  &:link,
  &:visited {
    color: var(--color-grey-300);
    text-decoration: none;
  }

  .footer-newsletter--standalone & {
    color: var(--color-text-subtle);

    &:hover {
      color: var(--color-text-subtle);
    }
  }
}
